<template>
    <div class="auth"></div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import SpinnerLoader from '../../SpinnerLoader.vue'
export default {
    components: { TextField, HintIcon, SpinnerLoader },
    data: () => ({
        settings: {},

        loading: false,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
    },
    methods: {},
    async mounted() {
        const projectId = this.$router.currentRoute.params.id

        const connector = await this.$store.dispatch('project/createConnector', {
            projectId,
            connectorType:
                typeof this.currentAddingConnector !== 'object'
                    ? this.currentAddingConnector
                    : this.currentAddingConnector.type,
        })
        this.$store.commit('project/setLoggedAddingConnector', connector)
        this.$parent.$emit('login', { connector })
    },
}
</script>
<style lang="sass" scoped></style>

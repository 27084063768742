<template>
    <div class="auth">
        <div class="form-block pa-10">
            <v-row class="mx-0 mb-4" justify="center" align="center">
                <h2>{{ connectorInfo.name }}</h2>
            </v-row>
            <v-form v-model="valid">
                <v-row class="ma-0">
                    <TextField
                        color="main"
                        filled
                        v-model="credentials.mainEndpoint"
                        label="URL основной"
                        class="form-input"
                        required
                    ></TextField>
                    <TextField
                        color="main"
                        filled
                        v-model="credentials.addonEndpoint"
                        label="URL дополнения"
                        class="form-input"
                        required
                    ></TextField>
                    <TextField
                        color="main"
                        filled
                        v-model="credentials.user"
                        label="Логин"
                        class="form-input"
                        required
                    ></TextField>
                    <TextField
                        color="main"
                        filled
                        v-model="credentials.salonId"
                        label="ID салона"
                        class="form-input"
                        required
                    ></TextField>
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="credentials.password"
                        label="Пароль"
                        class="form-input"
                        required
                    ></TextField>
                    <TextField
                        type="password"
                        color="main"
                        filled
                        v-model="credentials.apiKey"
                        label="Ключ API"
                        class="form-input"
                        required
                    ></TextField>
                </v-row>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <v-btn
                    color="main"
                    class="white--text"
                    @click="login"
                    :disabled="btnDisabled"
                    :loading="loading"
                    >Войти</v-btn
                >
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import { login } from '@api/connectors/helix1C'
import { CONNECTOR_TYPES } from '@/vars/general'
export default {
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        credentials: {
            mainEndpoint: '',
            addonEndpoint: '',
            user: '',
            salonId: '',
            password: '',
            apiKey: '',
        },
        loading: false,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        connectorInfo() {
            return this.$store.state.connectorsList.find((el) => el.type === CONNECTOR_TYPES.helix1C)
        },
        btnDisabled() {
            return Object.values(this.credentials).some((el) => !el)
        },
    },
    methods: {
        async login() {
            this.loading = true
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.helix1C,
            })
            const isLogin = await login(connector, this.credentials)

            if (isLogin) {
                this.$store.commit(
                    'project/setLoggedAddingConnector',
                    Object.assign(connector, { salonId: this.credentials.salonId })
                )
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
            this.loading = false
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
.form-input
    width: 100%
</style>

<template>
    <div class="auth">
        <div class="form-block pa-10">
            <v-row class="mx-0 mb-4" justify="center" align="center">
                <h2>{{ currentAddingConnector.name }}</h2>
            </v-row>
            <v-form>
                <v-row class="ma-0 mt-12" justify="center">
                    <AmoCrmAuthButton :state="OAuthState" v-if="showButton" />
                    <SpinnerLoader v-else />
                </v-row>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import { login } from '@api/connectors/amoCrm'
import { CONNECTOR_TYPES } from '@/vars/general'
import AmoCrmAuthButton from '@/components/buttons/AmoCrmAuthBtn.vue'
import SpinnerLoader from '../../SpinnerLoader.vue'
export default {
    components: { TextField, HintIcon, AmoCrmAuthButton, SpinnerLoader },
    data: () => ({
        loading: false,
        showButton: true,
        connector: null,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        OAuthState() {
            return {
                projectId: this.$route.params.id,
                connectorType: CONNECTOR_TYPES.amoCrm,
            }
        },
    },
    watch: {
        '$route.query.code': {
            handler(code) {
                if (!code) {
                    this.showButton = true
                } else {
                    this.showButton = false
                    this.login(code, this.$route.query.referer)
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        async login(code, referer) {
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.amoCrm,
            })
            const isLogin = await login({ connector, projectId, authorizationCode: code })

            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', Object.assign(connector, { referer }))
                this.$parent.$emit('login')
            } else {
                this.$store.dispatch('callNotify', 'Ошибка')
                this.$router.push(`/project/${projectId}/addConnectorStage1`)
            }
        },
    },
    mounted() {},
    created() {
        this.connector = this.$store.dispatch('project/createConnector', {
            projectId,
            connectorType: CONNECTOR_TYPES.amoCrm,
        })
        console.log('this.connector', this.connector)
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
</style>

import { ApiWrapper } from '../../types/main'
import { apiCall, http } from '../http'
import { CONNECTOR_TYPES } from '@/vars/general'
interface LoginParams {
    connector: {
        connectorId: string
        projectId: string
    }
    credentials: {
        apiKey: string
        brandName: string
    }
}

export async function login({ connector, credentials }: LoginParams): Promise<ApiWrapper<any>> {
    const url = `prostoSMS/login`
    const res = apiCall<any>(() =>
        http.post(url.toString(), {
            json: {
                connector,
                credentials: {
                    API_KEY: credentials.apiKey,
                    BRAND_NAME: credentials.brandName,
                },
            },
        })
    )
    return res
}


import { computed, defineComponent, onBeforeUnmount, PropType, ref } from 'vue'
import config from '../../../config'

export default defineComponent({
    props: { state: Object as PropType<{ projectId: string; connectorType: string }> },
    components: {},
    setup(props, {}) {
        const integrationName = 'DataHeroes'
        const integrationDescription = 'Data Heroes — аналитический сервис-помощник для салонов красоты'
        const redirectUri = 'https://' + window.location.hostname + '/OAuthLogin'
        const logoUrl =
            'https://cdn.discordapp.com/attachments/1024644507154796634/1052533303065972747/logo-w.png'
        const secretsUri = config.apiEndpoint + '/downloader/amoCrmFull/login'
        const scopes = 'crm'
        const mode = 'popup'
        const messageListener = ref()
        var centerAuthWindow = function(url: string, title: string) {
            const w = 750
            const h = 580
            //@ts-ignore
            const dual_screen_left = window.screenLeft !== undefined ? window.screenLeft : screen.left
            //@ts-ignore
            const dual_screen_top = window.screenTop !== undefined ? window.screenTop : screen.top

            const width = window.innerWidth
                ? window.innerWidth
                : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width

            const height = window.innerHeight
                ? window.innerHeight
                : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height

            const left = width / 2 - w / 2 + dual_screen_left
            const top = height / 2 - h / 2 + dual_screen_top

            const new_window = window.open(
                url,
                title,
                'scrollbars, status, resizable, width=' +
                    w +
                    ', height=' +
                    h +
                    ', top=' +
                    top +
                    ', left=' +
                    left
            )

            new_window!.focus()
        }
        const openAuthWindow = () => {
            const url_array = [
                'https://www.amocrm.ru/oauth/',
                '?state=',
                JSON.stringify(props.state),
                '&mode=',
                mode,
                '&origin=',
                origin,
            ]
            url_array.push('&name=', encodeURIComponent(integrationName))
            url_array.push('&description=', encodeURIComponent(integrationDescription))
            url_array.push('&redirect_uri=', redirectUri)
            url_array.push('&secrets_uri=', secretsUri)
            url_array.push('&logo=', encodeURIComponent(logoUrl))
            const finalScopes = scopes.split(',')
            finalScopes.forEach(function(scope) {
                url_array.push('&scopes[]=', scope)
            })

            console.log('amo', url_array.join(''))

            centerAuthWindow(url_array.join(''), 'Предоставьте доступ')
        }
        const onCreated = () => {
            messageListener.value = window.addEventListener(
                'message',
                event => {
                    {
                        if (event.data.url) {
                            window.location = event.data.url
                        }
                    }
                },
                false
            )
        }
        onCreated()
        onBeforeUnmount(() => {
            window.removeEventListener('message', messageListener.value)
        })
        return { openAuthWindow }
    },
})

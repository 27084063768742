<template>
    <div class="auth">
        <div class="form-block pa-10">
            <div class="text-center">
                <h2 class="mb-4">Планфакт</h2>
            </div>

            <v-form v-model="valid">
                <v-text-field
                    prepend-inner-icon="mdi-key"
                    color="black"
                    filled
                    type="password"
                    v-model="apiKey"
                    label="Api ключ"
                    required
                ></v-text-field>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <v-btn color="main" class="white--text" @click="login" :disabled="btnDisabled">Войти</v-btn>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'PlanfactAuth',

    data: () => ({
        valid: false,
        validLogin: null,
        apiKey:
            'KYuXRAJCs2pZeOrmlPynBmgVitxbgENhxjjR8FgHQbf2ZaqlZ36dM0YuIYMhqVRNhfSJ2EMjsyGoPnZ7aoi1oziqHcuBJtVzqm5tb-SeU060diLxENUlZu5ShjI3DIEnbdL9EKrMm8TUi_Xco3jIRMACumJUDEsbibHQTvo8Xxm3tr1t-DQZsB2S5CNLq-AYDwhvPDSpaFkINZOBwK-f0LdQf4rz5UuxQIHulyeKXweKfVSUq_cMd9reqSKer1DJaTnNTaIKS25oMA51XaoWNt9s8WStdJ0VMKNAWxrE8wA7MHbNmxTCnENF4pT0N8X3ZW34HH0agi9lEDBI8w8jeOdn0dbbf29xRkQgYDHkoiR_zFofvjIg1wKdf1tJjU9OMEs6qyyMd4uoljh3oMbtzVLZUktHswYvSvpOJ_Bgb9aZ7V-z4wiqm80DH2eAgMYjfo3PgCqzGZtkDi2_A_V5h_OvXALFxwj8zRCovKi44vuAalmt6RtW9__GfOallSQaG_SCbta39_RhYCmFtf4YYgmSJUj3HW9SqJohxI1xfJuATfEHXavunXHIx516x2f6HUBGlg',
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        btnDisabled() {
            if (!this.apiKey || !this.valid) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            const credentials = {
                type: 'planfact',
                apiKey: this.apiKey,
            }
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: 'planfact',
            })
            const isLogin = await this.$store.dispatch('pf/planfactLogin', {
                creds: {
                    apiKey: this.apiKey,
                },
                connector: connector,
            })
            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
</style>

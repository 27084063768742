<template>
    <div class="auth">
        <div class="form-block pa-10">
            <v-row class="mx-0 mb-4" justify="center" align="center">
                <h2>Whatsapp</h2>
            </v-row>
            <v-form>
                <SpinnerLoader v-if="loading" />
                <div v-show="!loading" class="qr-wrapper">
                    <canvas v-show="!loading" id="canvas" :class="{ hidden: !qrCode }"></canvas>
                    <span>Отсканируйте QR код</span>
                    <div class="d-flex justify-center mt-6">
                        <v-btn outlined class="outlined-btn" @click="loginWithoutQr"
                            >Отсканировать позже
                        </v-btn>
                    </div>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import { getQrCode, init } from '@api/connectors/whatsapp'
import { CONNECTOR_TYPES } from '@/vars/general'
import QRCode from 'qrcode'
import websocket from '@root/src/websockets'
import { getInstance } from '@root/src/auth'
import SpinnerLoader from '@/components/SpinnerLoader.vue'
export default {
    components: { TextField, HintIcon, SpinnerLoader },
    data: () => ({
        validLogin: null,
        loading: false,
        qrCode: null,
        wsListener: null,
        connector: null,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
    },
    methods: {
        async login() {
            this.loading = true
            const projectId = this.$router.currentRoute.params.id
            const auth = getInstance()
            console.log(this.connector)
            if (!this.connector) {
                this.connector = await this.$store.dispatch('project/createConnector', {
                    projectId,
                    connectorType: CONNECTOR_TYPES.whatsapp,
                })
            }
            if (!this.wsListener) {
                this.wsListener = websocket.socket.on('WA_AUTH_SUCCESS', async data => {
                    if (data.connectorId !== this.connector.connectorId) return
                    this.$store.commit('project/setLoggedAddingConnector', {
                        ...this.connector,
                        alive: true,
                        phone: data.phone,
                        userId: auth.user.sub,
                    })
                    this.$parent.$emit('login')
                    websocket.socket.off('WA_AUTH_SUCCESS')
                })
            }
            try {
                this.qrCode = (
                    await getQrCode({
                        projectId,
                        connectorId: this.connector.connectorId,
                    })
                ).data.QR
            } catch (err) {
                console.error(err)
                this.$store.dispatch('callNotify', 'Ошибка при получении qr-кода')
            } finally {
                this.loading = false
            }

            const canvas = document.getElementById('canvas')
            QRCode.toCanvas(canvas, this.qrCode, error => {
                if (error) {
                    this.$store.dispatch('callNotify', 'Ошибка при получении qr-кода')
                }
            })
        },
        async loginWithoutQr() {
            const auth = getInstance()
            const projectId = this.$router.currentRoute.params.id
            this.connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.whatsapp,
            })
            this.$store.commit('project/setLoggedAddingConnector', {
                ...this.connector,
                alive: false,
                phone: null,
                userId: auth.user.sub,
            })
            this.$parent.$emit('login')
        },
    },
    mounted() {
        this.login()
    },
    destroyed() {
        websocket.socket.off('WA_AUTH_SUCCESS')
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
.hidden
    display: none
.qr-wrapper
    display: flex
    flex-direction: column
    align-items: center
</style>

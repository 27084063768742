<template>
    <div class="auth">
        <div>
            <h1>Авторизация не требуется</h1>
        </div>
        <div class="mt-6">
            <v-btn color="main" class="white--text" @click="login" :loading="loading">Далее</v-btn>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { login } from '@api/connectors/moiSklad'
import { CONNECTOR_TYPES } from '@/vars/general'
export default {
    components: {},
    data: () => ({
        loading: false,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        title() {
            return this.$store.state.connectorsList.find(
                (el) => el.connectorType === CONNECTOR_TYPES.moiSklad
            ).name
        },
    },
    methods: {
        async login() {
            this.loading = true
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.moiSklad,
            })
            const isLogin = await login(connector)
            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
            this.loading = false
        },
    },
    mounted() {
        //this.login()
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
</style>

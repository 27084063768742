<template>
    <div class="auth">
        <div class="mx-0 my-10">
            <h1>Подключение Data Heroes к ПрофСалон</h1>
            <h1>Подключение Data Heroes к вашему ПрофСалону</h1>
            <h3>
                Перейдите в раздел Настройки → Общие настройки → Интеграции → Вебхуки на внешний сервер (см.
                скриншот ниже)
            </h3>
            <ol>
                <li>Отметьте галочкой пункт <code>Включить отправку данных по событию</code></li>
            </ol>
            <ol start="2">
                <li>
                    В поле <code>Адрес сервера</code> укажите ссылку:
                    <strong>{{ webhookData.webhook }}</strong>
                </li>
            </ol>
            <ol start="3">
                <li>
                    Нажмите на кнопку <code>Сохранить</code>
                    <img
                        src="https://lh3.googleusercontent.com/Iw3sKMeBPxZPzAGp6ztx602cRJP2j_qYLcJHPeEU0iUIXsDr-vekvD9wwDbpmI1u00aVkMFQffE21l6GkBjKSeD-ptkv0-M33O_Kyl9siwB7MFj9r4MBtmgHuj1v4BaMJ8lbbHvP"
                        referrerpolicy="no-referrer"
                        alt="img"
                    />
                </li>
            </ol>
            <ol start="4">
                <li>
                    Направьте на почту <a href="clients@profsalon.org">clients@profsalon.org</a> письмо со
                    следующим текстом:
                </li>
            </ol>
            <div class="email-text-wrapper">
                <p>
                    Добрый день! Прошу предоставить компании Data Heroes учетные данные для подкючения API, а
                    также выгрузку всей истории по клиентам и посещениям филиала.
                </p>
            </div>

            <ol start="5">
                <li>
                    Нажмите внизу этой страницы кнопку
                    <img
                        src="https://lh6.googleusercontent.com/I3qv_WASZdLqbkxDtWtUYG00xLbffR2joKkFNoFpn9W_7kUX1nMHhLnATMzOhdPXjdQGfeGX6M1CkEsv_uKGdCrhAMl-SG0895yTeXmNknvgMiG4058JjFRWg8kgQCHGxGwl8SkT"
                        referrerpolicy="no-referrer"
                        alt="img"
                    />
                </li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p><em>Скриншот: «Настройки → Общие настройки → Интеграции → Вебхуки на внешний сервер»</em></p>
            <p>
                <img
                    src="https://lh5.googleusercontent.com/t7KbvcltHu0A_rJM5by4FcosxqOiD-xlh4rS8gCpBBvyWa3Lv4p1cWc32O2OnqZs0VNT75o9kD-8m8nHTzc_SpHLTdZDzwAI7z2h1i5TVqXUWMLw2lsGptysEIQsmMOiMVH2LybX"
                    referrerpolicy="no-referrer"
                    alt="img"
                />
            </p>
            <p>&nbsp;</p>

            <v-row class="ma-0" justify="end">
                <v-btn color="main" class="white--text" @click="login">Далее</v-btn></v-row
            >
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import { CONNECTOR_TYPES } from '@root/src/vars/general'
import { createPendingConnectorRecord, getWebhook } from '@root/src/api/pendingConnectors'
export default {
    name: 'YcAuthSimple',
    components: { TextField, HintIcon },
    data: () => ({
        webhookData: {},
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
    },
    methods: {
        async login() {
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.profSalon,
            })
            this.$store.commit('project/setLoggedAddingConnector', connector)
            this.$parent.$emit('login')
            createPendingConnectorRecord({
                userId: this.$auth.user.sub,
                userName: this.$auth.user.name,
                userEmail: this.$auth.user.email,
                projectId: this.project.id,
                projectName: this.project.name,
                connectorId: connector.connectorId,
                connectorType: connector.connectorType,
                webhookKey: this.webhookData.webhookKey,
                stage: 1,
            })
        },
        async setWebhook() {
            this.webhookData = await getWebhook()
        },
    },
    created() {
        this.setWebhook()
    },
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
img
    max-width: 1016px

h3
    margin-top: 16px
    margin-bottom: 16px
.direction-col
    flex-direction: column
.email-text-wrapper
    padding: 10px
    background-color: $light-gray
</style>

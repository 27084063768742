<template>
    <div class="auth">
        <div class="form-block pa-10">
            <div class="text-center">
                <h2 class="mb-4">Альфа CRM</h2>
            </div>

            <v-form v-model="valid">
                <v-text-field
                    color="black"
                    prepend-inner-icon="mdi-email"
                    filled
                    v-model="email"
                    label="Email"
                    required
                ></v-text-field>

                <v-text-field
                    prepend-inner-icon="mdi-earth"
                    color="black"
                    filled
                    v-model="url"
                    label="Url"
                    required
                ></v-text-field>

                <v-text-field
                    prepend-inner-icon="mdi-key"
                    color="black"
                    filled
                    v-model="apiKey"
                    label="Api ключ"
                    required
                ></v-text-field>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <v-btn color="main" class="white--text" @click="login" :disabled="btnDisabled">Войти</v-btn>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'AlfaAuth',

    data: () => ({
        validLogin: null,
        valid: false,
        email: 'ovezov3210@gmail.com',
        url: 'https://legenda.s20.online',
        apiKey: '3b178fe2-b6c3-11ea-a443-ac1f6b478310',
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        btnDisabled() {
            if (!this.apiKey || !this.email || !this.url || !this.valid) {
                return true
            }
            return false
        },
    },
    methods: {
        login() {},
        async login() {
            const credentials = {
                type: 'alfa',
                email: this.email,
                url: this.url,
                apiKey: this.apiKey,
            }
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: 'alfa',
            })
            const isLogin = await this.$store.dispatch('alfa/alfaLogin', {
                creds: {
                    email: this.email,
                    url: this.url,
                    apiKey: this.apiKey,
                },

                connector: connector,
            })
            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
</style>

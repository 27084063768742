import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { ApiWrapper, Connector } from '../../types/main'
import { getError } from '../../utils'

export async function login({
    connector,
    authorizationCode,
    referer,
}: any): Promise<ApiWrapper<any[] | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/downloader/amoCrmFull/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            ...connector,
            credentials: {
                code: authorizationCode,
                endpoint: `https://${referer}`,
                redirect_uri: window.location.origin + '/OAuthLogin',
            },
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    console.log('data', data)
    console.log('error', error)

    return { data, error: error }
}

<template>
    <div class="auth">
        <div class="form-block pa-10">
            <div class="text-center">
                <h2 class="mb-4">Zenmoney</h2>
                <v-btn @click="goToZenmoney" color="main white--text" class="mt-12"
                    >Перейти на сайт Zenmoney</v-btn
                >
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import cfg from '../../../../config'
export default {
    name: 'YcAuth',

    data: () => ({
        validLogin: null,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),
    },
    methods: {
        async login(code) {
            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: 'zenmoney',
            })
            const isLogin = await this.$store.dispatch('zm/login', {
                code,
                connector: connector,
            })

            if (isLogin) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = isLogin
        },
        goToZenmoney() {
            const url = `https://api.zenmoney.ru/oauth2/authorize/?response_type=code&client_id=${cfg.zmClientId}&redirect_uri=${cfg.zmRedirectUri}&consumer_key=${cfg.zmConsumerKey}`
            sessionStorage.setItem('currentOAuthProvider', 'zenmoney')
            sessionStorage.setItem('callbackUrlAfterOAuth', this.$router.currentRoute.path)
            window.location.replace(url)
        },
    },
    created() {
        const code = this.$router.currentRoute.query.code
        if (code) {
            this.login(code)
        }
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"mx-0 my-10"},[_c('h1',[_vm._v("Подключение Data Heroes к ПрофСалон")]),_c('h1',[_vm._v("Подключение Data Heroes к вашему ПрофСалону")]),_c('h3',[_vm._v(" Перейдите в раздел Настройки → Общие настройки → Интеграции → Вебхуки на внешний сервер (см. скриншот ниже) ")]),_vm._m(0),_c('ol',{attrs:{"start":"2"}},[_c('li',[_vm._v(" В поле "),_c('code',[_vm._v("Адрес сервера")]),_vm._v(" укажите ссылку: "),_c('strong',[_vm._v(_vm._s(_vm.webhookData.webhook))])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('p',[_vm._v(" ")]),_c('p',[_vm._v(" ")]),_vm._m(5),_vm._m(6),_c('p',[_vm._v(" ")]),_c('v-row',{staticClass:"ma-0",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"main"},on:{"click":_vm.login}},[_vm._v("Далее")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Отметьте галочкой пункт "),_c('code',[_vm._v("Включить отправку данных по событию")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{attrs:{"start":"3"}},[_c('li',[_vm._v(" Нажмите на кнопку "),_c('code',[_vm._v("Сохранить")]),_c('img',{attrs:{"src":"https://lh3.googleusercontent.com/Iw3sKMeBPxZPzAGp6ztx602cRJP2j_qYLcJHPeEU0iUIXsDr-vekvD9wwDbpmI1u00aVkMFQffE21l6GkBjKSeD-ptkv0-M33O_Kyl9siwB7MFj9r4MBtmgHuj1v4BaMJ8lbbHvP","referrerpolicy":"no-referrer","alt":"img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{attrs:{"start":"4"}},[_c('li',[_vm._v(" Направьте на почту "),_c('a',{attrs:{"href":"clients@profsalon.org"}},[_vm._v("clients@profsalon.org")]),_vm._v(" письмо со следующим текстом: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"email-text-wrapper"},[_c('p',[_vm._v(" Добрый день! Прошу предоставить компании Data Heroes учетные данные для подкючения API, а также выгрузку всей истории по клиентам и посещениям филиала. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{attrs:{"start":"5"}},[_c('li',[_vm._v(" Нажмите внизу этой страницы кнопку "),_c('img',{attrs:{"src":"https://lh6.googleusercontent.com/I3qv_WASZdLqbkxDtWtUYG00xLbffR2joKkFNoFpn9W_7kUX1nMHhLnATMzOhdPXjdQGfeGX6M1CkEsv_uKGdCrhAMl-SG0895yTeXmNknvgMiG4058JjFRWg8kgQCHGxGwl8SkT","referrerpolicy":"no-referrer","alt":"img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('em',[_vm._v("Скриншот: «Настройки → Общие настройки → Интеграции → Вебхуки на внешний сервер»")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":"https://lh5.googleusercontent.com/t7KbvcltHu0A_rJM5by4FcosxqOiD-xlh4rS8gCpBBvyWa3Lv4p1cWc32O2OnqZs0VNT75o9kD-8m8nHTzc_SpHLTdZDzwAI7z2h1i5TVqXUWMLw2lsGptysEIQsmMOiMVH2LybX","referrerpolicy":"no-referrer","alt":"img"}})])
}]

export { render, staticRenderFns }
<template>
    <div class="auth">
        <div class="form-block pa-10">
            <v-row class="mx-0 mb-4" justify="center" align="center">
                <h2>Телефония Билайн</h2>
            </v-row>
            <v-form v-model="valid">
                <v-row class="ma-0">
                    <TextField color="main" filled v-model="token" label="Токен" required />
                </v-row>
                <p v-if="validLogin === false" class="error--text">Не верные данные</p>
                <v-btn
                    color="main"
                    class="white--text"
                    @click="login"
                    :disabled="btnDisabled"
                    :loading="loading"
                    >Войти</v-btn
                >
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextField from '@/components/inputs/TextField.vue'
import HintIcon from '@/components/HintIcon.vue'
import cfg from '../../../../config'
import api from '@/api/api'
import { CONNECTOR_TYPES } from '@/vars/general'
export default {
    components: { TextField, HintIcon },
    data: () => ({
        validLogin: null,
        valid: false,
        token: '',
        loading: false,
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector']),

        btnDisabled() {
            if (!this.token) {
                return true
            }
            return false
        },
    },
    methods: {
        async login() {
            this.loading = true

            const projectId = this.$router.currentRoute.params.id
            const connector = await this.$store.dispatch('project/createConnector', {
                projectId,
                connectorType: CONNECTOR_TYPES.telephonyBeeline,
            })
            const { data, error } = await api.connectors.telephonyBeeline.login({
                connectorId: connector.connectorId,
                token: this.token,
            })
            const loginValid = !error
            if (loginValid) {
                this.$store.commit('project/setLoggedAddingConnector', connector)
                this.$parent.$emit('login')
            }
            this.validLogin = loginValid
            this.loading = false
        },
    },
}
</script>
<style lang="sass" scoped>
.auth
    display: flex
    justify-content: center
.form-block
    border: 1px solid #0000001F
    min-width: 360px
    min-height: 344px
    max-width: 460px
</style>
